<template>
  <v-container>
    <v-row no-gutters>
      <v-col cols="12" sm="8">
        <div>Sabine Pülsch</div>
        <div>Westsid 8, 27432 Bremervörde</div>
        <div>Tel.: <a href="tel:01753333235">0175 - 333 32 35</a></div>
        <div class="mb-4">E-Mail: <a href="mailto:sabine.puelsch@ewetel.net">sabine.puelsch@ewetel.net</a></div>
      </v-col>
      <v-col cols="12" sm="4">
        <leaflet-map />
      </v-col>
    </v-row>
  </v-container>
  <v-container>Bremervörde ist der Mittelpunkt des Elbe-Weser-Dreiecks, zentral gelegen zwischen Bremen, Bremerhaven, Cuxhaven und Hamburg.</v-container>
  <v-container>
    Die Ferienwohnung ist in ruhiger Lage, aber dennoch sind es nur 20 Gehminuten bis zum Stadtkern.
    Der Vörder See, Welt der Sinne, Wasserspielplatz, Minigolf und die Umweltpyramide sind 10 Gehminuten entfernt.
  </v-container>
  <v-container>Bremervörde hat viele schöne Wanderwege und Fahrradrouten zu den umliegenden Ortschaften mit ihren historischen Bauwerken.</v-container>
  <v-container>
    <v-row no-gutters>
      <v-col cols="12" lg="6">
        <ul class="mb-4 pl-5">
          <li>Ferienwohnung auf 83 m² Wohnfläche</li>
          <li>Erdgeschoss</li>
          <li>Küche ist vollständig eingerichtet inkl. Geschirrspüler, Mikrowelle, Kühl- und Gefrierschrank, Herd und sämtlicher Kleingeräte</li>
          <li>Inkl. Bettwäsche und Handtücher</li>
          <li>Kinderbett kann dazu gestellt werden</li>
          <li>WLAN inklusive</li>
          <li>Carporteinstellplatz</li>
        </ul>
        <div>Preis pro Übernachtung, inkl. Endreinigung für bis 2 Personen 55,-- € und für jede weitere Person zuzüglich 10,-- €</div>
        <div>Buchbar ab 5 Übernachtungen</div>
        <div class="mb-4">Anreise ab 14.00 Uhr, Abreise bis 10.00 Uhr</div>
      </v-col>
      <v-col cols="12" lg="6">
        <image-gallery />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ImageGallery from '@/components/ImageGallery.vue'
import LeafletMap from '@/components/LeafletMap.vue'

export default {
  components: { ImageGallery, LeafletMap },
  name: 'HomeView',
}
</script>
